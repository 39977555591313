import React, { Component } from "react";
import Accordion from "./Accordion";

class About extends Component {
  state = {
    sections: [
      {
        title: "What is Vault?",
        content:
          "Vault is a web app for you to store all of your passwords for all of your private accounts. You can copy your passwords easily from here when you need them. No reason for you to click on that “Forgot password” on any website anymore!",
        stat: true,
      },
      {
        title: "Why use Vault?",
        content:
          "It’s quite known that having different passwords for different websites is a good practice. But that can be a huge hustle for our minds since we usually create hundreds of accounts on different websites. That’s why Vault can come in handy when you forget one of them",
        stat: false,
      },
      {
        title: "Should I trust Vault?",
        content:
          "This web app is secure for use since a military level cryptography algorithm called AES is used to store all of your passwords. What does this mean for you? Well, it means that not even the programmer himself can get access to your private data! However! Since vault is the first official web app of a bachelor student, please use it with caution. DO NOT STORE CRITICAL PASSWORDS! This app is meant for your accounts that do not contain important personal data like your email, facebook or bank account.",
        stat: false,
      },
      {
        title: "Who created Vault?",
        content:
          "Vault was entirely created by Samer Bahri, a passionate 22-year-old student, as a personal app to store his own passwords. It was published on June 2020.",
        stat: false,
      },
    ],
  };
  update = (index) => {
    let sections = this.state.sections;
    sections[index].stat = !sections[index].stat;
    this.setState({ sections: sections });
  };
  render() {
    return (
      <div className="columns">
        <div className="column">
          <nav className="panel">
            <p className="panel-heading">About</p>
            <section class="accordions">
              {this.state.sections.map((elem, index) => (
                <Accordion
                  stat={elem.stat}
                  key={index}
                  section={index}
                  title={elem.title}
                  content={elem.content}
                  update={this.update}
                />
              ))}
            </section>
          </nav>
        </div>
      </div>
    );
  }
}

export default About;
