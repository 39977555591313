import React, { Component } from "react";
import Upload from "./Upload";

class Settings extends Component {
  render() {
    return (
      <div className="columns">
        <div className="column">
          <nav className="panel">
            <p className="panel-heading">About</p>
            {/* <Formik
              initialValues={{
                oldPassword: "",
                newPassword: "",
                newEmail: "",
                text: "",
              }}
              onSubmit={(data) => this.sendMessage(data)}
            >
              {({ values }) => (
                <Form>
                  <FieldForm
                    label="Subject"
                    className="input"
                    type="input"
                    name="subject"
                    value={values.subject}
                  />
                  <FieldForm
                    label="Message"
                    className="textarea"
                    type="input"
                    name="text"
                    as="textarea"
                    value={values.text}
                  />
                  <Upload values={props.values} />
                  <button
                    type="submit"
                    onClick={this.handleSubmit}
                    className="button is-success"
                  >
                    Send
                  </button>
                </Form>
              )}
            </Formik> */}
          </nav>
        </div>
      </div>
    );
  }
}

export default Settings;
