import React, { Fragment, Component } from "react";
import axios from "axios";
import { Formik, Form } from "formik";
import FieldForm from "../Home/Components/Websites/Form/FieldForm";
import Notification from "../Home/Components/Websites/Notification";

class Help extends Component {
  state = { notification: false };
  sendMessage = (postData) => {
    axios({
      url: "/api/help/",
      method: "POST",
      headers: {
        "x-auth-token": window.sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: JSON.stringify(postData),
    })
      .then((data) => {
        window.sessionStorage.setItem("help", "sent");
        window.location.href = process.env.CLIENT_URL || "https://vault.thebahrimedia.com";
      })
      .catch((error) => {
        window.sessionStorage.setItem("help", "error");
        window.location.href = process.env.CLIENT_URL || "https://vault.thebahrimedia.com";
      });
  };
  closeNotif = () => this.setState({ notification: false });
  render() {
    return (
      <div className="columns">
        <div className="column">
          <nav className="panel">
            <p className="panel-heading">Help</p>
            <Formik
              initialValues={{ subject: "", text: "" }}
              onSubmit={(data) => this.sendMessage(data)}
            >
              {({ values }) => (
                <Form>
                  <FieldForm
                    label="Subject"
                    className="input"
                    type="input"
                    name="subject"
                    value={values.subject}
                  />
                  <FieldForm
                    label="Message"
                    className="textarea"
                    type="input"
                    name="text"
                    as="textarea"
                    value={values.text}
                  />
                  <button
                    type="submit"
                    onClick={this.handleSubmit}
                    className="button is-success"
                  >
                    Send
                  </button>
                </Form>
              )}
            </Formik>
          </nav>
        </div>
      </div>
    );
  }
}

export default Help;
