import React from "react";

const Accordion = props => {
  return (
    <article class={`accordion ${props.stat ? "is-active" : ""}`}>
      <div class="accordion-header">
        <p>{props.title}</p>
        <button
          class="toggle"
          aria-label="toggle"
          onClick={() => props.update(props.section)}
        ></button>
      </div>
      <div class="accordion-body">
        <div class="accordion-content">{props.content}</div>
      </div>
    </article>
  );
};

export default Accordion;
