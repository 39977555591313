import React from "react";
import { Field } from "formik";

const Checkbox = props => {
  let i = 0;
  return (
    <div className="field" style={{ textAlign: "left" }}>
      <Field
        name={props.name}
        //className="is-checkradio"
        id={`${props.name}${props.label}`}
        type="checkbox"
        value={props.value}
      />
      
      <label htmlFor={`${props.name}${props.label}`}>{props.label}</label>
    </div>
  );
};

export default Checkbox;
